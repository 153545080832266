import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { loadUserInfo } from "./store/actions/auth/authActions";
import "react-phone-number-input/style.css";
import "./styles/style.scss";
import queryString from "query-string";
import "react-confirm-alert/src/react-confirm-alert.css";

const userToken = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
if (userToken) {
  store.dispatch(loadUserInfo());
}

// set coupon code to localstorage for use in billing address page
const queryParams = queryString.parse(window.location.search);
const couponCode = queryParams?.couponCode || "";
const impactClickId = queryParams?.irclickid || "";
localStorage.setItem("couponCode", couponCode);
if (impactClickId) {
  localStorage.setItem("impactClickId", impactClickId);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

if ("serviceWorker" in navigator) {
  // Check if a service worker is already registered
  if (!navigator?.serviceWorker?.controller?.state === "activated") {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope
        );
      })
      .catch((error) => {
        console.error("Service Worker registration failed:", error);
      });
  }
}
